/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://skls5kopincv5aagyzwzceeyji.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-jx4yzl47mjbvtgivls7smoapwe",
    "aws_cognito_identity_pool_id": "eu-central-1:889a857f-e656-4be1-beed-d6ee7e34d449",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_7hEyxCFzI",
    "aws_user_pools_web_client_id": "1gqa858eib40msu28qnvnc2dmf",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "imagebucket81107-staging",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
